import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const PosicionamientoSEOPage = () => {
    const data = useStaticQuery(graphql`
    query {
      posicionamientoSeoImage1: file(relativePath: { eq: "images/sections/marketing-digital/posicionamiento-seo/por-que-una-empresa-debe-hacer-seo-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      posicionamientoSeoImage2: file(relativePath: { eq: "images/sections/marketing-digital/posicionamiento-seo/consultores-seo-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      posicionamientoSeoImage3: file(relativePath: { eq: "images/sections/marketing-digital/posicionamiento-seo/ventajas-posicionamiento-seo-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Posicionamiento SEO"
                description="Agencia de Marketing Digital en Barcelona experta en posicionamiento SEO. Posicionamos orgánicamente webs en las primeras posiciones de resultados de Google."
            />

            <BannerPage
                title="Agencia Posicionamiento SEO"
                text="Consigue que tu web esté entre las primeras posiciones en Google"
                color="green"
            />

            <SectionTextImage
                title1="¿Por qué "
                title2="hacer SEO?"
                text={<Fragment>
                    Sólo el 7% de los usuarios que navega en la red pasa de la primera página de resultados de un buscador, por lo que liderar esta lista es el objetivo prioritario de cualquier empresa. <br /><br />
                    Si no estás en Internet, es como si no existieras. Hay que tener una buena <strong>presencia online, un excelente posicionamiento SEO</strong>. Eso va a determinar el éxito o fracaso de tu marca. ¿Analizamos en qué posición estás?
                </Fragment>}
                image={data.posicionamientoSeoImage1.childImageSharp.fluid}
                imageAlt="¿Por qué hacer SEO? - Agencia Posicionamiento SEO"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Somos tus "
                title2="consultores SEO"
                text={<Fragment>
                    En IBX, nuestro objetivo es <strong>optimizar tu web o comercio electrónico</strong> en lo más alto de los <strong>motores de búsqueda</strong> tales como Google, Bing y Yahoo. Para ello, realizamos una estrategia en posicionamiento <strong>orgánico y/o natural</strong> en los buscadores web, conocido como <strong>SEO</strong> (Search Engine Optimization). <br /><br />
                    Con IBX, <strong>aumentarás la visibilidad de tu negocio en los buscadores</strong> en los términos de búsqueda (palabras claves o Keywords) más relevantes y en las ubicaciones donde esté tu público objetivo. ¡Dinos dónde y con qué palabras quieres posicionar tu empresa!
                </Fragment>}
                image={data.posicionamientoSeoImage2.childImageSharp.fluid}
                imageAlt="IBX Consultoría SEO - Agencia Posicionamiento SEO"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Ventajas del "
                title2="posicionamiento SEO"
                text={<Fragment>
                    <ul class="text-left">
                        <li>Aumento de <strong>visitas o tráfico a tu web</strong></li>
                        <li>Mayor posibilidad de <strong>ventas y contactos</strong></li>
                        <li>Mayor <strong>temporalidad en la red</strong></li>
                        <li>Mayor <strong>visibilidad e imagen</strong></li>
                        <li><strong>Menor inversión</strong> económica</li>
                        <li><strong>Reputación</strong> online</li>
                    </ul>
                </Fragment>}
                image={data.posicionamientoSeoImage3.childImageSharp.fluid}
                imageAlt="Ventajas del posicionamiento SEO - Agencia Posicionamiento SEO"
                color="green"
                containerTextRight={false}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="green"
                silverText="Ideal para Startups y pymes"
                silverPrice="190"
                silverPriceCurrency="€/mes"
                silverFeatures={["Análisis básicos de palabras clave", "SEO básico: on-site", "Geolocalización en Google My Business", "8 Link Buildings", "Estudio de estrategias para captar nuevos clientes", "Monitorización y Análisis de actividad", "Informes básicos de resultados"]}
                silverException={[]}
                goldText="Ideal para negocios consolidados"
                goldPrice="250"
                goldPriceCurrency="€/mes"
                goldFeatures={["Análisis palabras clave", "SEO avanzado: on-site y off-site", "Geolocalización en Google My Business", "12 Link Buildings", "Estrategia de captación", "Monitorización y Análisis de actividad", "Informes avanzados de resultados"]}
                goldException={[]}
                diamondText="Ideal para grandes empresas"
                diamondPrice="490"
                diamondPriceCurrency="€/mes"
                diamondFeatures={["Auditoría web", "Análisis palabras clave", "SEO avanzado: on-site y off-site", "Geolocalización en Google My Business", "16 Link Buildings", "Estrategia de captación", "Monitorización, Análisis e Informes premium de resultados"]}
                diamondException={[]}
            />

            <CallToAction
                color="green"
                title="¿Quieres mejorar la visibilidad de tu negocio?"
                text={<Fragment>¡Somos la <strong>agencia SEO de Barcelona</strong> que necesitas!</Fragment>}
                button="¡Consigue posicionar tu web en lo más alto de Google! ¡Llámanos!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default PosicionamientoSEOPage
